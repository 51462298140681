.banana {
    display: none;
}
@media (min-width: 1024px) {
    .banana {
        display: block;
        position: absolute;
        right: 6rem;
        bottom: 4rem;
        max-width: 9rem;
        max-height: 9rem;
        transform: rotate(-45deg);
        z-index: 1;
    }
}