.bicycle {
    display: none;
}
@media (min-width: 1024px) {
    .bicycle {
        display: block;
        position: absolute;
        left: -4rem;
        bottom: 1.5rem;
        max-width: 18rem;
        max-height: 18rem;
        transform: rotate(-15deg);
        z-index: 1;
    }
}