.welcome {
    position: relative;
    z-index: 0;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
}
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.headerIcon {
    height: 2rem;
}
.headerText {
    font-family: var(--font-head-main);
    color: var(--color-cta-main);
    font-size: 2.25rem;
    margin: 0;
}
.text {
    line-height: 1.6;
    font-family: var(--font-text-main);
    color: var(--color-text-main);
    margin-bottom: 2rem;
    word-spacing: 5px;
    letter-spacing: 1px;
    text-align: center;
}
@media (min-width: 500px) {
    .welcome {
        padding: 2.5rem 0;
    }
    .header {
        gap: 2rem;
    }
    .headerIcon {
        height: 2.5rem;
    }
    .headerText {
        font-size: 2.5rem;
    }
    .text {
        font-size: 1.2rem;
        margin-bottom: 2.5rem;
    }
}
@media (min-width: 992px) {
    .welcome {
        padding: 3rem 0;
    }
    .headerIcon {
        height: 3rem;
    }
    .headerText {
        font-size: 3rem;
    }
    .text {
        font-size: 1.7rem;
        margin-bottom: 3rem;
    }
}