.sneakers {
    display: none;
}
@media (min-width: 1024px) {
    .sneakers {
        display: block;
        position: absolute;
        right: -3rem;
        top: 7rem;
        max-width: 11rem;
        max-height: 11rem;
        transform: scaleX(-1) rotate(20deg);
        z-index: 1;
    }
}