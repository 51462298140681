.gauge,
.sliceItem:nth-child(2),
.gaugeCenter,
.rank {
    transition: var(--transition-sec);
}
.gauge {
  position: relative;
  background: var(--color-sec);
  box-shadow: 0 -3px 6px 2px rgba(0, 0, 0, 0.50);
  width: 16rem;
  height: 8rem;
  border-radius: 8rem 8rem 0 0!important;
  overflow: hidden;
}
.sliceColors {
  height:100%;
}
.sliceItem {
  position: absolute;
  bottom: 0;
}
.sliceItem:nth-child(1) {
  left: 0;
  background-color: var(--color-wrong);
  width: 50%;
  height: 100%;
}
.sliceItem:nth-child(2) {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border: 8rem solid transparent;
  border-top-color: var(--color-cta-main);
  z-index: 1;
}
.sliceItem:nth-child(3) {
  background-color: var(--color-success);
  width: 50%;
  height: 100%;
  right:0;
}
.gaugeCenter {
  position: absolute;
  color: var(--color-sec);
  width: 60%;
  height: 60%;
  background: var(--color-text-sec);
  border-radius: 8rem 8rem 0 0!important;
  box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  color: var(--color-sec);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 5;
}
.rank {
  font-size: 1.5rem;
  font-family: var(--font-text-main);
  margin: 0 0 1rem;
}
.needle {
  position: absolute;
  left: 7%;
  bottom: 0%;
  transform-origin: 100%;
  display:block;
  background: var(--color-text-sec);
  border-bottom-left-radius: 100%!important;
  border-bottom-right-radius: 5px!important;
  border-top-left-radius: 100%!important;
  border-top-right-radius: 5px!important;
  width: 43%;
  height: 0.5rem;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
  z-index: 3;
}
@media (min-width: 500px) {
  .gauge {
    width: 20rem;
    height: 10rem;
    border-radius: 10rem 10rem 0 0!important;
  }
  .sliceItem:nth-child(2) {
    border: 10rem solid transparent;
    border-top-color: var(--color-cta-main);
  }
  .gaugeCenter {
    border-radius: 10rem 10rem 0 0!important;
  }
  .rank {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .gauge {
    width: 24rem;
    height: 12rem;
    border-radius: 12rem 12rem 0 0!important;
  }
  .sliceItem:nth-child(2) {
    border: 12rem solid transparent;
    border-top-color: var(--color-cta-main);
  }
  .gaugeCenter {
    border-radius: 12rem 12rem 0 0!important;
  }
  .rank {
    font-size: 2.5rem;
  }
  .needle {
    height: 0.65rem;
  }
}
