.btn {
    display: block;
    border: 0;
    outline: none;
    padding: 0.7rem 1.5rem;
    width: 80%;
    max-width: 12rem;
    cursor: pointer;
    background-color: transparent;
    border: 3px solid var(--color-text-main);
    color: var(--color-text-main);
    font-family: var(--font-head-main);
    transition: var(--transition-main);
}
.btn:hover {
    background-color: var(--color-text-main);
    border: 3px solid var(--color-text-main);
    color: var(--color-sec);
}
@media (min-width: 500px) {
    .btn {
        padding: 0.7rem 2rem;
        font-size: 1.05rem;
    }
}