.errBox {
    width: 70vw;
    max-width: 30rem;
    background-color: var(--color-sec);
    padding: 2rem;
    box-shadow: 0 0 1rem 5px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
}
.errIcon {
    width: 4rem;
}
.errHeader {
    color: var(--color-wrong);
    font-family: var(--font-head-main);
    font-size: 1.5rem;
    margin: 0;
}
.errText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-text-main);
    color: var(--color-text-main);
}
@media (min-width: 768px) {
    .errBox {
        padding: 3rem 6rem;
        gap: 1rem;
    }
    .errIcon{
        width: 6rem;
    }
    .errHeader {
        font-size: 2rem;
    }
    .errText {
        font-size: 1.5rem;
    }
}