.rankBoard {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.note {
    font-family: var(--font-text-main);
    color: var(--color-text-main);
    text-align: center;
}