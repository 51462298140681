:root {
  --font-head-main: 'Space Mono', monospace;
  --font-text-main: 'Chakra Petch', sans-serif;
  --color-bg-main: #70E2DA;
  --color-bg-sec: #CFF7F1;
  --color-main: #47D4D3;
  --color-sec: #F4FEFD;
  --color-text-main: #0e3c5e;
  --color-text-sec: #15222E;
  --color-success: #43a047;
  --color-wrong: #e53935;
  --color-shadow-main: #7a9598;
  --color-shadow-sec: #dde0d5;
  --color-border-main: #bde1dd;
  --color-border-sec: #c3eae5;
  --color-border-third: #dafefa;
  /* --color-cta-main: #FE9A37; */
  --color-cta-main: #f1c40f;
  --transition-main: 0.3s;
  --transition-sec: 0.6s;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
