.orange {
    display: none;
}
@media (min-width: 1024px) {
    .orange {
        display: block;
        position: absolute;
        left: 11rem;
        top: 7rem;
        max-width: 9rem;
        max-height: 9rem;
        transform: rotate(-45deg);
        z-index: 1;
    }
}