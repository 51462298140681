.qs {
    position: relative;
}
.bar {
    height: 0.75rem;
    background-color: var(--color-main);
    width: 0%;
    transition: var(--transition-sec);
}
.headerText {
    font-size: 1.6rem;
    padding: 0.5rem 2rem;
    text-align: center;
    color: var(--color-text-main);
    font-family: var(--font-head-main);
    word-spacing: 2px;
    letter-spacing: 1px;
    line-height: 1.6;
    user-select: none;
    text-transform: capitalize;
}
.border {
    position: relative;
    background-color: var(--color-border-sec);
    height: 1px;
}
.border:before,
.border:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
} 
.border:before {
    background-color: var(--color-border-main);
    height: 1px;
    bottom: -1px;
}
.border:after {
    background-color: var(--color-border-third);
    height: 2px;
    bottom: -3px;
}
.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 3rem;
}
.opts {
    width: 100%;
    max-width: 20rem;
    margin: 0 auto;
    z-index: 3;
}
.opt {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    background-color: var(--color-sec);
    border-radius: 12px;
    padding: 0.75rem 1rem;
    box-shadow: 0 0 10px 2px rgba(var(--color-shadow-main), 0.2);
    overflow: hidden;
    cursor: pointer;
    transition: calc(var(--transition-main) * 0.75);
}
.opt:not(:last-of-type) {
    margin-bottom: 1rem;
}
.opt::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    background-color: var(--color-main);
    transition: var(--transition-sec);
}
.opt:hover.opt::before,
.selected.selected::before {
    width: 100%;
}
.optNum,
.optText {
    transition: var(--transition-main);
    color: var(--color-text-main);
    font-family: var(--font-text-main);
    font-size: 1.1rem;
    text-transform: capitalize;
    z-index: 1;
    user-select: none;
}
.optNum {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background-color: var(--color-bg-sec);
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
    box-shadow: 1px 1px 3px 2px var(--color-shadow-sec);
}
.opt:hover .optNum,
.opt:hover .optText,
.selected .optNum,
.selected .optText{
    color: var(--color-sec);
}
.opt:hover .optNum,
.selected .optNum {
    background-color: var(--color-text-main);
    box-shadow: none;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.icon {
    display: none;
    position: absolute;
    right: 0.3rem;
    top: 50%;
    transform: translateY(-50%);
    height: 2rem;
    z-index: 10;
}
.correct,
.wrong {
    opacity: 1;
}
.correct {
   box-shadow: 0 0 9px 3px var(--color-success); 
}
.wrong {
   box-shadow: 0 0 9px 3px var(--color-wrong); 
}
.correct .correctIcon,
.wrong .wrongIcon {
    display: block;
}
@media (min-width: 500px) {
    .bar {
        height: 1rem;
    }
    .headerText {
        font-size: 2.75rem;
        padding: 0.5rem 2rem;
    }
    .border {
        height: 2px;
    }
    .border:before {
        height: 2px;
        bottom: -2px;
    }
    .border:after {
        height: 4px;
        bottom: -6px;
    }
    .body {
        padding: 3rem;
    }
    .opt {
        padding: 1rem 1.5rem;
    }
    .opt:not(:last-of-type) {
        margin-bottom: 1.5rem;
    }
    .optNum,
    .optText {
        font-size: 1.3rem;
    }
    .optNum {
        width: 1.8rem;
        height: 1.8rem;
    }
    .icon {
        right: 0.5rem;
        height: 2.25rem;
    }
}