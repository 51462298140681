.loader {
  width: 0.5rem;
  height: 3rem;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-text-main);
  color: var(--color-text-main);
  animation: animloader 0.3s 0.3s linear infinite alternate;
}
.loader::after, .loader::before {
  content: '';
  width: 0.5rem;
  height: 3rem;
  border-radius: 5px;
  background: var(--color-text-main);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}
.loader::before {
  left: -20px;
  animation-delay: 0s;
}
@keyframes animloader {
  0% {
    height: 3rem;
  }
  100% {
    height: 0.5rem;
  }
}

