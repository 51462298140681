.home {
    background-color: var(--color-bg-sec);
    background-image: url('../../assets//bg.svg');
    width: 95vw;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 55rem;
}
.home > div {
    width: 100%;
}